import {IntlProvider} from 'react-intl';
import React, {createContext, PropsWithChildren, useContext, useEffect, useMemo, useReducer, useState,} from 'react';

import * as gtm from 'src/lib/gtm';
import * as storage from 'src/lib/storage';
import {PROJECT_ALIAS} from 'src/constants/app';
import {getTranslate} from 'src/services/TranslatesService';
import {useRequestSimple} from 'src/hooks/useRequestSimple';
import {Status} from 'src/lib/remoteData';
import {differenceInDays} from 'date-fns';
import Loader from 'src/components/Loader';
import * as Sentry from '@sentry/browser';
import {Snackbar} from '@mui/material';
import Alert from '@mui/material/Alert';

export type Locale =
  | 'es'
  | 'en'
  | 'pt'
  | 'id'
  | 'th'
  | 'vi'
  | 'ms'
  | 'tl'
  | 'fr'
  | 'it'
  | 'de';

type State = Locale;
type Dispatch = (action: Action) => void;
type Action = { type: 'setLocale'; payload: { locale: Locale } };

const STORAGE_KEY = 'rw.locale';
const getLocalStorageLocales = (lang: string) => `rw.locale.${lang}`;

// @TODO: add flag image
const locales: Record<Locale, string> = {
  en: 'English',
  es: 'Español',
  it: 'Italiana',
  de: 'Deutsch',
  fr: 'Française',
  pt: 'Português (BR)',
  id: 'Bahasa Indonesia',
  th: 'ภาษาไทย',
  vi: 'Tiếng Việt',
  ms: 'Bahasa Malaysia',
  tl: 'Filipino',
};

export const getProjectLocales = () => {
  if (PROJECT_ALIAS === 'biward') {
    return {
      en: 'English',
    };
  }

  return locales;
};

const defaultLocale = Object.keys(locales)[0];
const navigatorLocale = navigator.language.split('-')[0];
const detectedLocale =
  navigatorLocale in locales ? navigatorLocale : defaultLocale;

const initialState = storage.get(STORAGE_KEY, detectedLocale) as Locale;
const LocaleStateContext = createContext<State | undefined>(initialState);
const LocaleDispatchContext = createContext<Dispatch | undefined>(undefined);

gtm.set('lang', detectedLocale);

function localeReducer(_: State, action: Action): State {
  switch (action.type) {
    case 'setLocale':
      const language = action.payload.locale;
      storage.set(STORAGE_KEY, language);
      gtm.set('lang', language);
      return language;
    default:
      throw new Error(`Unhandled action`);
  }
}

const useMessages = () => {
  const [currentLocation, setCurrentLocation] = useState('');
  const [error, setError] = useState(null);
  const { data, makeRequest, isLoading } = useRequestSimple((loc) =>
    getTranslate(loc)
  );
  const responseMessages =
    data.status === Status.Success ? data.data || {} : {};

  const localStorageMessages = useMemo(() => {
    const locales = storage.get(getLocalStorageLocales(currentLocation));

    if (differenceInDays(new Date(locales?.lastUpdate), new Date()) >= 1) {
      return null;
    }

    return locales?.data || null;
  }, [currentLocation]);

  useEffect(() => {
    setError(null);
    if (!currentLocation || localStorageMessages) return;

    const request = async () => {
      const response = await makeRequest(currentLocation);

      if (
        response.status === Status.Success &&
        Object.keys(response.data).length
      ) {
        storage.set(getLocalStorageLocales(currentLocation), {
          data: response.data,
          lastUpdate: new Date(),
        });
      } else {
        setError(response.status === Status.Failure && response.error);
      }
    };

    request().then();
  }, [currentLocation, localStorageMessages]);

  return {
    messages: localStorageMessages || responseMessages,
    setCurrentLocation,
    isLoading,
    error
  };
};

export function useLocaleState() {
  const context = useContext(LocaleStateContext);

  if (context === undefined) {
    throw new Error('useLocaleState must be used within a WorldProvider');
  }

  return context;
}

export function useLocaleDispatch() {
  const dispatch = useContext(LocaleDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useLocaleDispatch must be used within a WorldProvider');
  }

  return {
    setLocale: (locale: Locale) =>
      dispatch({ type: 'setLocale', payload: { locale } }),
  };
}

export default function LocaleProvider({ children }: PropsWithChildren) {
  const [locale, dispatch] = useReducer(localeReducer, initialState);
  const { messages, setCurrentLocation, error } = useMessages();
  const [prevLocale, setPrevLocale] = useState(locale);
  const [isChanging, setIsChanging] = useState(false);
  const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);

  useEffect(() => {
    setCurrentLocation(locale);
  }, [locale]);

  useEffect(() => {
    if (error) {
      Sentry.captureException('empty captchaV3 token', (scope) => {
        scope.setExtra('lang', locale);
        return scope;
      });
      setIsOpenErrorMessage(true);
      dispatch({ type: 'setLocale', payload: { locale: prevLocale } });
    }
  }, [error]);

  useEffect(() => {
    if (Object.keys(messages).length && !error) {
      setIsChanging(true);
      setPrevLocale(locale);
      setTimeout(() => setIsChanging(false), 0);
    }
  }, [messages, error]);

  return (
    <LocaleStateContext.Provider value={locale}>
      <LocaleDispatchContext.Provider value={dispatch}>
        <IntlProvider locale={locale} messages={messages || {}}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isOpenErrorMessage}
            autoHideDuration={6000}
            onClose={() => setIsOpenErrorMessage(false)}
          >
            <Alert
              onClose={() => setIsOpenErrorMessage(false)}
              severity="error"
              variant="filled"
            >
              Failed to load translations, please try again later.
            </Alert>
          </Snackbar>
          {isChanging ? <Loader fullSize /> : children}
        </IntlProvider>
      </LocaleDispatchContext.Provider>
    </LocaleStateContext.Provider>
  );
}
