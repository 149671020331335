import {failure, RemoteDataResult, success} from "src/lib/remoteData";
import axios from "axios";

export const getTranslate = async (lang: string): Promise<RemoteDataResult<Record<string, string>>> => {
    try {
        const response = await axios.get<Record<string, string>>(`/api/v1/translate`, {
            params: {
                lang
            },
        });

        return success(response.data);
    } catch (error) {
        return failure(error);
    }
};