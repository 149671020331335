import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router';
import Typography from '@mui/material/Typography';
import { SvgIcon, Theme } from '@mui/material';

import Reviews from 'src/components/Landing/Reviews';

import { ReactComponent as ArrowIcon } from 'src/assets/landing-arrow_down.svg';
import EarningUserStream from 'src/components/EarningUserStream';
import PrizeBlock from './PrizeBlock';
import { RegPrizeService } from 'src/services/RegFlowService';
import { useDesignParams } from 'src/hooks/useDesign';
import ArticlesList from 'src/components/articles/articles-list';

const prizes: Array<0> = Array(4).fill(0);

const Prizes = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '12px',
          marginBottom: '12px',
        }}
      >
        {prizes.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: '92px',
              height: '92px',
              background: `#FFFFFF no-repeat url(/landing-prizes/prize${
                index + 1
              }.png) 50% 50%`,
              borderRadius: '12px',
            }}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '12px',
          overflow: 'hidden',
          height: '60px',
          position: 'relative',
          margin: 'auto',
          width: 'max-content',
          '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            background:
              'linear-gradient(180deg, rgba(243, 245, 247, 0.50) 0%, #F3F5F7 100%)',
          },
        }}
      >
        {prizes.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: '92px',
              height: '92px',
              background: `#FFFFFF no-repeat url(/landing-prizes/prize${
                index + 5
              }.png) 50% 50%`,
              borderRadius: '12px',
            }}
          />
        ))}
      </Box>
    </>
  );
};

const regularContainerStyles = (img: string) => (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: `url(${img}) no-repeat 10% 100%/50%`,
  flexWrap: 'wrap',
  paddingBottom: 6,
  marginTop: 6,
  [theme.breakpoints.down('md')]: {
    paddingBottom: 20,
    backgroundPosition: '50% 100%',
    backgroundSize: '250px',
    marginTop: 1,
  },
});

const newContainerStyles = (img: string) => (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: `url(${img}) no-repeat 0% 0px/40%`,
  paddingBottom: 2,
  backgroundSize: '550px',

  [theme.breakpoints.down('md')]: {
    height: '330px',
    backgroundSize: '400px',
    backgroundPosition: '50% 100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const LandingPage: FunctionComponent<{
  icon?: string;
  image?: string;
  isNewDesign?: boolean;
}> = ({ icon, image, isNewDesign }) => {
  const intl = useIntl();
  const { offerId } = useDesignParams();

  useEffect(() => {
    RegPrizeService.setState({
      icon,
      image,
      isNewDesign,
      offerId,
    });
  }, []);

  return (
    <>
      <PrizeBlock image={image} icon={icon} isNewDesign={isNewDesign}>
        <Outlet />
      </PrizeBlock>
      <Box
        sx={{
          background: '#E8F4FF',
        }}
      >
        <Container
          component="section"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            overflow: 'hidden',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={(theme) => ({
              flex: 1,
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingTop: 5,
              '& p': {
                fontSize: '2.25em',
                fontWeight: 700,
              },
              [theme.breakpoints.down('md')]: {
                flexBasis: '100%',
                flex: '0 auto',
              },
            })}
          >
            <Typography
              sx={{
                maxWidth: '550px',
              }}
            >
              {intl.formatMessage({
                id: 'get_tickets',
                defaultMessage:
                  'Get tickets for simple tasks and participate in the prize draw',
              })}
            </Typography>
            <Typography
              sx={{
                color: '#3D25D2',
              }}
            >
              {intl.formatMessage({
                id: 'how_to_do_it',
                defaultMessage: "Here's how to do it...",
              })}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: '0 auto',
              maxWidth: '410px',
              marginX: 'auto',
              width: '100%',
            }}
          >
            {isNewDesign && (
              <Box
                sx={{
                  width: '100%',
                  marginTop: 2,
                }}
              >
                <EarningUserStream source="landing" />
              </Box>
            )}
            <Box
              sx={{
                background:
                  '#FFFFFF url(/landing-tickets.png) no-repeat 16px 36px',
                maxWidth: '400px',
                paddingLeft: '112px',
                paddingRight: '16px',
                paddingY: 2,
                marginX: 'auto',
                marginBottom: 2.5,
                marginTop: 5,
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.25em',
                  fontWeight: 600,
                  marginBottom: 2,
                }}
              >
                {intl.formatMessage({
                  id: 'earn_tickets',
                  defaultMessage: 'Earn tickets',
                })}
              </Typography>
              <Typography sx={{ fontSize: '1em' }}>
                {intl.formatMessage({
                  id: 'earn_tickets_instruction',
                  defaultMessage:
                    'Shop online, play video games, search the internet, answer questions and find great deals to earn your tickets',
                })}
              </Typography>
            </Box>
            <SvgIcon
              component={ArrowIcon}
              inheritViewBox
              sx={{
                marginX: 'auto',
                display: 'block',
                marginBottom: 2.5,
              }}
            />
            <Box
              sx={{
                textAlign: 'center',
                marginBottom: 5,
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.25em',
                  fontWeight: 600,
                  marginBottom: 2,
                }}
              >
                {intl.formatMessage({
                  id: 'get_prizes',
                  defaultMessage: 'Get prizes',
                })}
              </Typography>
              <Typography sx={{ fontSize: '1em' }}>
                {intl.formatMessage({
                  id: 'exchange_tickets',
                  defaultMessage:
                    'Exchange your tickets for participation in a large number of prize draws',
                })}
              </Typography>
            </Box>
            <Prizes />
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          background: '#FFFFFF',
          paddingY: 5,
        }}
      >
        <Container
          component="section"
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <iframe
            width="560"
            height="350"
            src="https://www.youtube.com/embed/uNRdnlmmCb8?si=ULFUTrQdy7T2lu-w"
            title="YouTube video player"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Container>
      </Box>
      <Box
        sx={{
          background: '#E8F4FF',
          paddingY: 5,
        }}
      >
        <Container component="section">
          <Typography
            sx={{
              fontSize: '2.25em',
              fontWeight: 700,
              marginLeft: 0,
              paddingBottom: 2,
            }}
          >
            {intl.formatMessage({
              id: 'articles.inspire_us',
              defaultMessage: 'Inspire Us',
            })}
          </Typography>
          <ArticlesList />
        </Container>
      </Box>
      <Box
        sx={{
          background: '#F3F5F7',
          paddingY: 5,
        }}
      >
        <Container component="section">
          <Typography
            sx={{
              fontSize: '2.25em',
              fontWeight: 700,
              marginLeft: 0,
            }}
          >
            {intl.formatMessage({
              id: 'reviews_title',
              defaultMessage: 'People are talking about us',
            })}
          </Typography>
        </Container>
        <Container component="section">
          <Box
            sx={{
              overflowY: 'scroll',
              marginTop: 4,
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Reviews />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LandingPage;
